<template>
  <div class="myNewsCenter">
    <!-- 新闻中心 -->
    <van-nav-bar
      :title="$t('home.name43')"
      left-text
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
    />
    <van-search
      v-model="searchVal"
      placeholder="请输入搜索关键词"
      @input="onSearch"
    />
    <div class="content">
      <van-pull-refresh v-model="isLoadingAwait" @refresh="onRefreshAwait">
        <van-list
          v-model="loadingAwait"
          :finished="finishedAwait"
          finished-text="没有更多了"
          offset="200"
          @load="onLoadAwait"
        >
          <van-cell
            class="ass_cell"
            v-for="(item, index) in assessList"
            :key="index"
            center
            :label="item.crdate"
            @click="goToDetails(item)"
          >
            <template #title>
              <div class="news_title">{{ item.newstitle }}</div>
            </template>
            <!-- <template #icon>
              <van-image class="news_img"
                         width="110"
                         height="65"
                         lazy-load
                         src="https://img01.yzcdn.cn/vant/cat.jpeg" />
            </template> -->
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
    <!-- <div class="footer">
      <van-button type="info"
                  size="small"
                  @click="goExamine">发起指标申请</van-button>
    </div> -->
  </div>
</template>

<script>
import { getAllNewsList } from '@api/wx.js'
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
export default {
  data() {
    return {
      userInfo,
      isLoadingAwait: false,
      loadingAwait: false,
      finishedAwait: false,
      newstitle: '',
      pageindex: 1,
      pagesize: 10,
      userid: localStorage.getItem('userid'),
      assessList: [],
      searchVal: ''
    }
  },
  created() {},
  methods: {
    onClickLeft() {
      this.$router.push('/home')
    },
    // 获取所有记录
    getAllNews() {
      let _this = this
      getAllNewsList({
        newstitle: _this.newstitle,
        pageindex: _this.pageindex,
        pagesize: _this.pagesize,
        userid: _this.userid || localStorage.getItem('userid')
      }).then(res => {
        /*
        只有第一页能直接赋值
        _this.assessList = res.data
        后续应该是合并数组模式
        */

        if (res.data.length == 0) {
          _this.finishedAwait = true
          // 无数据
        } else if (res.data.length == 10) {
          if (_this.pageindex == 1) {
            _this.assessList = res.data
          } else {
            _this.assessList = [..._this.assessList, ...res.data]
          }
          // _this.assessList = res.data
          _this.loadingAwait = false
        } else if (res.data.length < 10) {
          if (_this.pageindex == 1) {
            _this.assessList = _this.assessList.concat(res.data)
          } else {
            _this.assessList = [
              ..._this.assessList,
              ..._this.assessList.concat(res.data)
            ]
          }
          // _this.assessList = _this.assessList.concat(res.data)
          _this.loadingAwait = false
          _this.finishedAwait = true
        }
        _this.pageindex++
        // this.totalcount = parseInt(res.totalcount)
        // this.newsList = res.data;
      })
    },
    onLoadAwait() {
      // 异步更新数据
      if (this.isLoadingAwait) {
        this.assessList = []
        this.isLoadingAwait = false
      }
      this.getAllNews()
    },
    // 下拉刷新
    onRefreshAwait() {
      this.pageindex = 1
      this.pagesize = 10
      this.assessList = []
      this.finishedAwait = false
      this.loadingAwait = true
      this.onLoadAwait()
    },
    // 跳转详情页
    goToDetails(item) {
      const from = 'center'
      this.$router.push({
        path: '/newsDetails',
        query: { autoid: item.autoid, from: from }
      })
    },
    // 搜索
    onSearch(val) {
      if (val) {
        this.assessList = this.assessList.filter(item => {
          return item.newstitle.indexOf(val) != -1
        })
      } else {
        this.onRefreshAwait()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.myNewsCenter {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .van-search {
    margin-top: 1.22667rem;
  }
  .content {
    overflow: auto;
    height: calc(100% - 212px);
    .ass_cell {
      /deep/.news_img {
        .van-image__img {
          border-radius: 8px;
        }
      }
      .van-cell__title {
        margin-left: 16px;
        .news_title {
          // line-height: 1.5;
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .van-cell__label {
          text-align: right;
          font-size: 24px;
          font-family: Helvetica Neue;
          font-weight: bold;
          color: #999999;
        }
      }
    }
  }
}
</style>
